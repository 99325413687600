




























import { Vue, Component, Prop } from 'vue-property-decorator'
import SvgMeasureLines from './SvgMeasureLines.vue'
import CourtColors, { CourtColor } from '../helpers/CourtColors'

const angled = `transform: perspective(15em) translate3d(0, -20%, 40px)  rotate3d(1, 0, 0, 40deg)`
const flat = `transform: translateY(-10%) perspective(0em) rotateX(0deg)`
const lineStyle = `fill:none;stroke:#666;stroke-width:0.2px;`
const textStyle = `fill:#666;stroke:none;`

interface BasketballViewerData {
  border: CourtColor
  halfCourt: CourtColor
  innerCenterCir: CourtColor
  outerCenterCir: CourtColor
  '3pt1': CourtColor
  key1: CourtColor
  keyCircle1: CourtColor
  '3pt2': CourtColor
  key2: CourtColor
  keyCircle2: CourtColor
}

@Component({
  components: {
    SvgMeasureLines
  }
})
export default class BasketballCourtViewer extends Vue {
  @Prop({ default: 188 }) width!: number
  @Prop({ default: 300 }) height!: number
  @Prop({ default: 'angled' }) angle!: 'flat' | 'angled'
  @Prop({
    default: () => {
      return {
        border: CourtColors[0],
        halfCourt: CourtColors[0],
        innerCenterCir: CourtColors[0],
        outerCenterCir: CourtColors[0],
        '3pt1': CourtColors[0],
        key1: CourtColors[0],
        keyCircle1: CourtColors[0],
        '3pt2': CourtColors[0],
        key2: CourtColors[0],
        keyCircle2: CourtColors[0]
      }
    }
  })
  zones!: BasketballViewerData

  lineStyle = lineStyle
  textStyle = textStyle
  angled = angled
  flat = flat
  trans = { x: 12, y: 12, width: 50, height: 84 }
  lines = [
    {
      start: { x: 52, y: 0 },
      end: { x: 52, y: 84 }
    },
    {
      start: { x: -2, y: 0 },
      end: { x: -2, y: 42 }
    },
    {
      start: { x: 22, y: 86 },
      end: { x: 28, y: 86 }
    },
    {
      start: { x: 19, y: 91 },
      end: { x: 31, y: 91 }
    },
    {
      start: { x: 0, y: 86 },
      end: { x: 5.25, y: 86 }
    },
    {
      label: 'Side Line',
      start: { x: -60, y: -2, r: -90 }
    }
  ]

  get displayAngle(): string {
    return this.angle === 'angled' ? angled : flat
  }
}
